'use client'
import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
import EnquiryPopUp from '@/components/popupComponents/EnquiryPopup';
import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
import IMAGES from '@/utils/validations/ImagePaths';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';


const Enquiry = () => {
    const theme = useTheme();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const handleClick = () => {
        setIsPopupOpen(true);
    };

    return (

        <Box sx={{
            p: 2,
            // paddingTop: "50px",
            // paddingBottom: "50px",
            // minHeight: "30vh",
            height: "70vh"
        }}>



            <Grid container spacing={0} sx={{ backgroundColor: "#F5F5DC" }}>



                <Grid item xs={12} md={6} style={{
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center', flexDirection: 'column', height: '70vh'
                }}>
                    <Grid sx={{
                        borderRadius: '30px', border: '3px solid #CB4335', padding: '20px',
                        margin: '20px'
                    }}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                height: '100%',
                                textAlign: 'center',
                                padding: theme.spacing(2),
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h2" sx={{ color: 'black' }}>Have a question?</Typography>
                            <Typography variant="h4" sx={{ color: 'black' }}>
                                We would love to hear from you! Please use the form below to get in touch with us. Our team is dedicated to
                                providing timely and helpful responses to all enquiries.
                            </Typography>
                            <ShadowButtonSubmit
                                height="40px"
                                width="50%"
                                minwidth="100px"
                                maxwidth="125px"
                                // backgroundcolor={theme.palette.warning.dark}
                                backgroundcolor="#F4C430"
                                type="button"
                                onClick={handleClick}
                            >
                                <SubmitButtonText color="#CB4335">Enquiry</SubmitButtonText>
                            </ShadowButtonSubmit>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                    <Box sx={{
                        backgroundImage: `url(${IMAGES?.ENQUIRY.default?.src})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "60%",
                        width: "90%"
                    }}></Box>
                </Grid>

                <EnquiryPopUp
                    isDialogOpened={isPopupOpen}
                    handleCloseDialog={() => {
                        setIsPopupOpen(false);
                    }}
                />
            </Grid>
        </Box>

    );
};

export default Enquiry;