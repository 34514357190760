import { SubTitle, Title } from '@/components/textComponents';
import { Box, Card, CardActionArea, CardMedia, Stack } from '@mui/material';

// const ImageCard = ({ imageUrl, label, width, height, start, end }) => {
//   if (!imageUrl) {
//     return null;
//   }

//   return (
//     <Card
// sx={{
//   width: '100%',
//   maxWidth: { sm: 120, xs: '100%', md: '400px' },
//   height: '100%',

// }}
//     >
//       <CardActionArea
// href={imageUrl}
// target="_blank"
//       >
//         <CardMedia
//           component="img"
//           image={imageUrl}
//           alt={label || 'Image'}
//           sx={{ height: '400px' }}
//         />

//         <Box
//           sx={{
//             position: 'absolute',
//             bottom: 0,
//             left: 0,
//             width: '100%',
//             // backgroundColor: 'rgba(0, 0, 0, 0.7)',
//             color: 'white',
//             padding: '8px',
//             display: 'flex',
//             // justifyContent: 'space-between',
//             // alignItems: { xs: 'center', md: 'flex-end' },
//             // flexDirection: { xs: 'column', md: 'row' },
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <Stack
//             direction="column"
//             spacing={0.1}
//             alignItems="center"
//             justifyContent="center"
//           >
//             <Typography variant="subtitle1" sx={{ color: 'white' }}>
//               {label || 'Image'}
//             </Typography>
//             <Typography variant="body2" sx={{ color: 'white' }}>
//               {start ? `${start}` : ''}
//             </Typography>
//             <Typography variant="body2" sx={{ color: 'white' }}>
//               {end ? `${end}` : ''}
//             </Typography>
//           </Stack>
//         </Box>
//       </CardActionArea>
//     </Card>
//   );
// };

const ImageCard = ({ imageUrl, label, start, end }) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <CardActionArea
      // href={imageUrl}
      // target="_blank"
      >
        <CardMedia
          component="img"
          image={imageUrl}
          alt={label || 'Image'}
          sx={{
            width: '100%',
            height: {
              xs: '300px', // On extra-small devices
              sm: '400px', // On small devices
              md: '500px', // On medium devices
              lg: '600px', // On large devices
              xl: '800px', // On extra-large devices
            },
            objectFit: 'contain',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
            padding: '10px',
            boxSizing: 'border-box',
          }}
        >
          <Stack
            direction="column"
            // spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Title sx={{ color: 'white' }}>{label || 'Image'}</Title>
            {start && (
              <SubTitle sx={{ color: 'white' }}>{`Start: ${start}`}</SubTitle>
            )}
            {end && (
              <SubTitle sx={{ color: 'white' }}>{`End: ${end}`}</SubTitle>
            )}
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ImageCard;
