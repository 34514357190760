'use client';
import Categories from '@/components/pageComponents/Web/Categories';
import About from '@/pageComponents/Web/About';
import ContactUs from '@/pageComponents/Web/ContactUs';
import Enquiry from '@/pageComponents/Web/Enquiry';
import Footer from '@/pageComponents/Web/Footer';
import Header from '@/pageComponents/Web/Header';
import ImageSlider from '@/pageComponents/Web/ImageSlider';
import Map from '@/pageComponents/Web/Map';
import OurPartners from '@/pageComponents/Web/OurPartners';
import RecentVideos from '@/pageComponents/Web/RecentVideos';
import StaticBanner from '@/pageComponents/Web/StaticBanner';
import { Box, Container, Stack } from '@mui/material';

import { useRef } from 'react';

export default function Home() {
  const mapRef = useRef(null);
  const categoriesRef = useRef(null);
  const videosRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <Container maxWidth="xl" disableGutters>
        <Header
          mapRef={mapRef}
          categoriesRef={categoriesRef}
          videosRef={videosRef}
          aboutRef={aboutRef}
          contactRef={contactRef}
        />

        <Stack direction="column" spacing={1}>
          <StaticBanner />
          <ImageSlider />
          {/* <DynamicBanner banners={data.banners} /> */}
          {/* <Box ref={mapRef}> */}
          <Map mapRef={mapRef} />
          {/* </Box> */}
          <Box ref={categoriesRef}>
            <Categories />
          </Box>

          {/* <Categories ref={categoriesRef} /> */}
          <Box ref={videosRef}>
            <RecentVideos />
          </Box>

          <Box ref={aboutRef}>
            <About />
          </Box>

          <OurPartners />

          <Enquiry />

          <Box ref={contactRef}>
            <ContactUs />
          </Box>
        </Stack>
        <Footer />
      </Container>
    </>
  );
}
