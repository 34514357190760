'use client';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import { SubTitle } from '@/components/textComponents/index';
import useReadMore from '@/hooks/useReadMore';
import { callApi } from '@/utils/apirequest';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRouter } from 'next/navigation';

import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import config from 'config';
import { useCallback, useContext, useEffect, useState } from 'react';

const Categories = () => {
  const { setLoading } = useContext(LoadingContext);
  const [categoryList, setCategoryList] = useState([]);
  const router = useRouter();

  const getCategoryList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await callApi('getCategoryList', {
        activeStatus: true,
      });

      if (response && response.data && response.data.result) {
        const trimmedCategories = response.data.result.map(res => ({
          id: res._id,
          ...res,
        }));
        setCategoryList(trimmedCategories);
      } else {
        setCategoryList([]);
      }
    } catch (err) {
      console.error('Failed to fetch category list:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCategoryList();
  }, []);

  const {
    displayCount,
    handleReadMoreClick,
    handleReadLessClick,
    displayData,
    showMoreButton,
    showReadLessButton,
  } = useReadMore(categoryList, 12);

  const remainingCount = categoryList.length - displayCount;

  const handleClick = categoryName => {
    const queryParams = new URLSearchParams({
      // user: "exampleUser",
      // role: "admin",
      name: categoryName,
    }).toString();

    // router.push(`/searchbusinessunit`);
    router.push(`/searchbusinessunit?${queryParams}`);
  };

  return (
    <>
      {categoryList && categoryList?.length > 0 && (
        <Stack direction="column" sx={{ p: 2 }}>
          <Typography variant="h2" align="center" sx={{ mb: 5 }}>
            Popular Categories
          </Typography>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {displayData.map(category => (
              <Grid item key={category.id} xs={4} sm={4} md={2} lg={2}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  style={{ cursor: 'pointer' }}
                >
                  <Card
                    sx={{
                      width: '100%',
                      maxWidth: { sm: '100px', xs: '1000px', md: '100px' },
                      height: '100%',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        onClick={() => handleClick(category?.categoryName)}
                        component="img"
                        image={`${config?.host}${category?.categoryImage?.imageURL}`}
                        alt={category?.categoryName || 'Image'}
                        sx={{ height: '80px' }}
                      />
                    </CardActionArea>
                  </Card>
                  <SubTitle>{category.categoryName}</SubTitle>
                </Stack>
              </Grid>
            ))}

            {showMoreButton && (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Button onClick={handleReadMoreClick} fullWidth>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <ExpandMoreIcon />+{remainingCount}
                  </Stack>
                </Button>
              </Grid>
            )}
            {showReadLessButton && (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Button onClick={handleReadLessClick} fullWidth>
                  <ExpandLessIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default Categories;
