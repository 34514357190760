'use client';
import IMAGES from '@/utils/validations/ImagePaths';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Image from 'next/image';

const StaticBanner = () => {
  const theme = useTheme();
  return (
    <Stack>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(to right, #FFB300, #C62828)',
          minHeight: '500px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundImage: 'linear-gradient(to right, #FFB300, #C62828)',
            padding: '50px 50px',
            position: 'relative',
          }}
        >
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography
                    variant="h2"
                    sx={{
                      marginBottom: '30px',
                      color: theme.palette.common.grey,
                      whiteSpace: 'nowrap',
                      // textAlign: "center",
                      [theme.breakpoints.up('md')]: {
                        textAlign: 'left',
                      },
                    }}
                  >
                    Make A Difference
                  </Typography>

                  <Box
                    textAlign="justify"
                    sx={{
                      wordSpacing: '0.5em', // Adjust the spacing as needed
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: '30px',
                      }}
                    >
                      Nelamangala, a burgeoning suburban area near Bangalore,
                      has seen a surge in modern shopping outlets. With a mix of
                      rural and urban residents, many commute daily for work due
                      to its proximity to industrial areas. Offering a serene
                      living environment and easy access to Bangalore's
                      amenities, it's an ideal suburban location where awareness
                      of local resources is crucial as business thrives.
                    </Typography>
                  </Box>

                  <Stack
                    direction={{ xs: 'row', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 2 }}
                    // direction="row"
                    // spacing={2}
                    justifyContent="flex-start" // Align items towards the left
                    textAlign="left" // Align text towards left
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: theme.palette.common.grey }}
                      >
                        2943
                      </Typography>

                      <Typography
                        variant="h4"
                        sx={{ color: theme.palette.primary.main }}
                      >
                        Events
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: theme.palette.common.grey }}
                      >
                        1M+
                      </Typography>

                      <Typography
                        variant="h4"
                        sx={{ color: theme.palette.primary.main }}
                      >
                        Followers
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              {/* Show Image for larger screens, hide for mobile */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: 'none', md: 'block' }, // Hide for xs, show for md and up
                    textAlign: 'center',
                    alignItems: 'center',
                    marginTop: '-50px',
                  }}
                >
                  <Image
                    src={IMAGES?.NN}
                    alt="Banner Image"
                    width={300}
                    height={300}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Stack>
  );
};

export default StaticBanner;
