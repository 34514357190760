'use client';
import IMAGES from '@/utils/validations/ImagePaths';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useState } from 'react';

const aboutDetails = {
  mission: `Namaste Nelamangala boosts local businesses in Bangalore and Karnataka through extensive outreach. It originally aimed to reduce dependence on Bangalore by showcasing local businesses. Services include creating audio-video reels highlighting products/services and local culture, shared on Instagram, Facebook, and YouTube. This immediate exposure helps businesses attract potential clients/customers across the area.`,
};

const About = () => {
  // const theme = useTheme();
  const [buttonColor, setButtonColor] = useState('secondary');

  const handleClick = () => {
    // Change button color when clicked
    setButtonColor('primary');
    console.log('Button clicked!');
  };

  return (
    <Stack sx={{ p: 2 }}>
      <Box style={{ position: 'relative', minHeight: '100vh' }}>
        <Image
          src={IMAGES?.ABOUT_US}
          alt="Background Image"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />

        <Grid
          container
          md={12}
          style={{ position: 'relative', zIndex: 1, padding: '20px' }}
        >
          <Grid item xs={12} md={6}></Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <Grid
              sx={{
                borderRadius: '30px',
                border: '3px solid white',
                padding: '20px',
              }}
            >
              <Typography variant="h2" gutterBottom={2} sx={{ color: 'white' }}>
                About Us
              </Typography>
              <Box textAlign={'justify'}>
                <Typography variant="h4" sx={{ color: 'white' }}>
                  {aboutDetails.mission}
                </Typography>
              </Box>
              {/* <Button
                onClick={handleClick}
                color={buttonColor}
                className="mobile-button"
                style={{
                  marginTop: "20px",
                  width: "100%", // Set the initial width to 100%
                  maxWidth: "150px", // Set a maximum width to avoid excessive expansion
                  borderRadius: "8px", // Add border radius
                  backgroundColor: "Background", // Set the background color of the button
                  color: "#de4f44", // Set the text color of the button
                  fontWeight: "bold"// Add more styles here if needed
                }}
              >
                Learn More
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default About;
