'use client';

import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import ImageCard from '@/reusableComponents/CardComponents/ImageCard';
import { formatDate } from '@/utils/CommonFunctions/DateRelatedFunctions';
import { callApi } from '@/utils/apirequest';
import { Box } from '@mui/material';
import config from 'config';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const swiperStyles = `
.swiper-button-next,
.swiper-button-prev {
  color: red;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}

.swiper-pagination-bullet {
  background-color: rgba(255, 0, 0, 0.5);
}

.swiper-pagination {
  margin: -12px 0;
}
`;

// const navigationStyles = {
//   color: 'red',
//   transform: 'scale(0.8)',
//   width: '40px',
// };

const ImageSlider = () => {
  const swiperRef = useRef(null);
  const { setLoading } = useContext(LoadingContext);
  const [images, setImages] = useState([]);

  const getAllBanners = useCallback(async () => {
    setLoading(true);
    try {
      const response = await callApi('getBanner');
      if (response && response.data) {
        setImages(response.data);
      } else {
        setImages([]);
      }
    } catch (err) {
      console.error('Failed to fetch category list:', err);
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    getAllBanners();
  }, [getAllBanners]);

  const handleSlideClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      if (swiper.autoplay.running) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }
    }
  };

  return (
    <>
      {images && images.length > 0 && (
        <>
          <Box
            sx={{
              px: 1,
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh',
            }}
          >
            <Swiper
              slidesPerView={'auto'}
              cssMode={true}
              speed={5000}
              allowTouchMove={true}
              slideToClickedSlide={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                waitForTransition: false,
                reverseDirection: false,
              }}
              modules={[Navigation, Pagination, Autoplay]}
              ref={swiperRef}
              pagination={{ clickable: true }}
              loop={true}
              observer
              observeParents
              preventClicks={true}
              loopedSlides={images.length}
              loopFillGroupWithBlank={true}
              preventClicksPropagation={true}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index} onClick={handleSlideClick}>
                  <Box
                    sx={{
                      py: 3,
                      mx: 2,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ImageCard
                      imageUrl={`${config?.host}${image?.image?.imageURL}`}
                      label={image?.name || ''}
                      sx={{
                        '& .label': {
                          backgroundColor: '#CB4335',
                          padding: '2px 6px',
                        },
                      }}
                      start={formatDate(image.fromDate, 'datetime') || ''}
                      end={formatDate(image.endDate, 'datetime') || ''}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </>
      )}
    </>
  );
};

export default ImageSlider;
