export function formatDate(dateTime, type = 'date') {
    if (dateTime && (typeof dateTime === 'string' || dateTime instanceof Date)) {
      // Parse input date-time string as a Date object
      const date = new Date(dateTime);
  
      // Get day, month, and year components
      const day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
  
      // Check the type parameter and format the date accordingly
      let formattedDateTime = '';
      if (type === 'date') {
        formattedDateTime = `${day}/${month}/${year}`;
      } else if (type === 'month') {
        month = new Date(date.getTime()).toLocaleString('default', {
          month: 'short',
        });
        formattedDateTime = `${month} ${year}`;
      } else if (type === 'datetime') {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12;
  
        formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${amPm}`;
      }
  
      return formattedDateTime;
    } else {
      return '';
    }
  }