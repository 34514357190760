'use client';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import { SubTitle } from '@/components/textComponents/index';
import { callApi } from '@/utils/apirequest';
import { formatDate } from '@/utils/CommonFunctions/DateRelatedFunctions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import config from 'config';
import { useCallback, useContext, useEffect, useState } from 'react';

const RecentVideos = () => {
  const [playingIndex] = useState(null);

  const handlePlayVideo = videoLink => {
    window.open(videoLink, '_blank');
  };
  const { setLoading } = useContext(LoadingContext);
  const [videoDetails, setvideoDetails] = useState([]);

  const getRecentVideos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await callApi('getRecentVideos');
      console.log(response, 'JJJJ');
      if (response && response.data.result) {
        setvideoDetails(response.data.result);
      } else {
        setvideoDetails([]);
      }
    } catch (err) {
      console.error('Failed to fetch Recent Videos:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getRecentVideos();
  }, []);

  return (
    <>
      {videoDetails && videoDetails.length > 0 && (
        <Stack sx={{ p: 2 }}>
          <CardContent>
            <Typography
              variant="h2"
              align="center"
              marginBottom={4}
              style={{ fontWeight: 'bold' }}
            >
              Recent Videos
            </Typography>
            <Grid
              container
              spacing={9}
              alignItems="center"
              justifyContent="center"
              sx={{ borderColor: 'red' }}
            >
              {videoDetails &&
                videoDetails.length > 0 &&
                videoDetails.map((element, index) => (
                  <Grid item xs={6} sm={6} md={4} lg={2} key={index}>
                    <Stack
                      direction="column"
                      alignItems="center"
                      sx={{ maxWidth: 300 }}
                      spacing={2}
                    >
                      <div
                        className="video-thumbnail"
                        style={{
                          backgroundColor: 'lightblue',
                          display: 'grid',
                          placeItems: 'center',
                        }}
                      >
                        {playingIndex === index ? (
                          <video
                            controls
                            src={`${config?.host}${element.businessVideoURL}`}
                            autoPlay
                            style={{ width: '150px', height: '200px' }}
                          />
                        ) : (
                          <>
                            <img
                              src={`${config?.host}${element.businessImage.imageURL}`}
                              alt={element.businessName}
                              width="150"
                              height="200"
                              style={{
                                gridColumn: '1',
                                gridRow: '1',
                                justifySelf: 'center',
                                alignSelf: 'center',
                              }}
                            />
                            <IconButton
                              sx={{
                                gridColumn: '1',
                                gridRow: '1',
                                justifySelf: 'center',
                                alignSelf: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                padding: '10px',
                                borderRadius: '50%',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                },
                              }}
                              onClick={() =>
                                handlePlayVideo(`${element.businessVideoURL}`)
                              }
                            >
                              <PlayCircleOutlineIcon
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  color: '#000',
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </div>

                      <Stack direction="column" spacing={2}>
                        <SubTitle textAlign="center">
                          {element.businessName}
                        </SubTitle>
                        <SubTitle> {formatDate(element.createdAt)}</SubTitle>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Stack>
      )}
    </>
  );
};

export default RecentVideos;
