//LATEST
'use client';

import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
import TextfieldWrapper from '@/components/formComponents/Textfield';
import {
  otherPropsNotRequired,
  otherPropsRequired,
} from '@/components/formComponents/requiredStyleJson/otherProps';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
import { setAlertPopup } from '@/store/alertSlice';
import { callApi } from '@/utils/apirequest';
import {
  emailValidation,
  nameOtherValidationNoRegex,
  nameValidation,
  validateContactNumber,
} from '@/utils/validations/CommonValidations';
import IMAGES from '@/utils/validations/ImagePaths';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MessageIcon from '@mui/icons-material/Message';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';

import {
  Box,
  CardContent,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const INITIAL_FORM_STATE = {
  name: '',
  contactNo: '',
  email: '',
  remarks: '',
  instagramLink: '',
  youtubeLink: '',
  facebookLink: '',
};

const FORM_VALIDATION = Yup.object().shape({
  name: nameValidation('Name', true),
  contactNo: validateContactNumber('Contact Number', true),
  email: emailValidation('Email Id', true),
  remarks: nameOtherValidationNoRegex('Remarks', false),
  instagramLink: Yup.string().url('Invalid Instagram URL').nullable(),
  youtubeLink: Yup.string().url('Invalid YouTube URL').nullable(),
  facebookLink: Yup.string().url('Invalid Facebook URL').nullable(),
});

const ContactUs = () => {
  const theme = useTheme();
  const { setLoading } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const submitDetails = async (values, { resetForm }) => {
    try {
      const data = {
        ...values,
      };

      const response = await callApi('createFeedBack', data);

      if (response.data.result) {
        dispatch(
          setAlertPopup({
            message: 'Feedback Form Submitted Successfully',
            type: 'success',
            duration: 3000,
          })
        );
        resetForm();
      } else {
        console.error('Failed to fetch:', response.data.message);
        dispatch(
          setAlertPopup({
            message: response.data.message,
            type: 'error',
            duration: 3000,
          })
        );
        resetForm();
      }
    } catch (err) {
      console.log('err', err);
      dispatch(
        setAlertPopup({
          message: 'Something went wrong Please try again!',
          type: 'error',
          duration: 3000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack sx={{ p: 2 }}>
      <Box
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundImage: `url(${IMAGES?.CONTACT_US.default?.src})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {/* <Image
          src={IMAGES?.CONTACT_US}
          alt="Background Image"
          layout="fill"
          //objectFit="cover"
          objectPosition="center"

        /> */}
        {/* <Box

        sx={{
          backgroundImage: `url(${IMAGES?.CONTACT_US?.default?.src})`,
          // backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: 'absolute',
          minHeight: '100vh',
          backgroundSize: "cover",
          backgroundPosition: 'center',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
        <div sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Card sx={{
            p: 2,
            minWidth: 100,
            marginLeft: 'auto',
            position: 'relative', // Add this line
            zIndex: 1, // Add this line with a value higher than the background image
          }}> */}
          <CardContent>
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid
                item
                xs={12}
                md={6}
                sm={6}
                alignItems={'right'}
                justifyContent={'right'}
                sx={{
                  border: '3px solid #CB4335',
                  borderRadius: '20px',
                  alignItems: 'flex-end',
                  justifyContent: 'right',
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  Feedback
                </Typography>

                <Formik
                  enableReinitialize
                  initialValues={{
                    ...INITIAL_FORM_STATE,
                  }}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={async (values, { resetForm }) => {
                    console.log(values);
                    // alert('Form submitted');
                    submitDetails(values, { resetForm });
                    // await new Promise(resolve => setTimeout(resolve, 1000));
                    // resetForm();
                  }}
                >
                  {({
                    // errors,
                    // handleBlur,
                    // handleChange,
                    handleSubmit,
                    // setFieldValue,
                    // isSubmitting,
                    // touched,
                    // values,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Box sx={{ p: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          alignItems={'right'}
                          justifyContent={'right'}
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="name"
                              label="Name"
                              otherProps={otherPropsRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AccountBoxRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="contactNo"
                              label="Contact Number"
                              otherProps={otherPropsRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SmartphoneRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="email"
                              label="Email Id"
                              otherProps={otherPropsRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="remarks"
                              label="Remarks"
                              minRows={4}
                              otherProps={otherPropsNotRequired}
                              multiline
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    sx={{
                                      marginTop: 3,
                                      marginLeft: 2,
                                      alignSelf: 'start',
                                    }}
                                  >
                                    <MessageIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h5" sx={{ color: 'Black' }}>
                              Note: Please copy the URL and paste the social
                              media links in the input area below.
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="instagramLink"
                              label="Instagram"
                              otherProps={otherPropsNotRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <InstagramIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="facebookLink"
                              label="Facebook"
                              otherProps={otherPropsNotRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FacebookIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextfieldWrapper
                              name="youtubeLink"
                              label="YouTube"
                              otherProps={otherPropsNotRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <YouTubeIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} container justifyContent="center">
                            <ShadowButtonSubmit
                              height="45px"
                              width="50%"
                              minwidth="100px"
                              maxwidth="500px"
                              backgroundcolor={theme.palette.primary.main}
                              type="submit"
                              onClick={handleSubmit}
                            >
                              <SubmitButtonText color="#fff">
                                Submit
                              </SubmitButtonText>
                            </ShadowButtonSubmit>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
          {/* </Card> */}
        </div>
      </Box>
    </Stack>
  );
};

export default ContactUs;

//RECENT - 2
// 'use client';

// import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
// import TextfieldWrapper from '@/components/formComponents/Textfield';
// import { otherPropsNotRequired } from '@/components/formComponents/requiredStyleJson/otherProps';
// import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
// import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
// import { setAlertPopup } from '@/store/alertSlice';
// import { callApi } from '@/utils/apirequest';
// import {
//   emailValidation,
//   nameOtherValidationNoRegex,
//   nameValidation,
//   validateContactNumber,
// } from '@/utils/validations/CommonValidations';
// import IMAGES from '@/utils/validations/ImagePaths';
// import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
// import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import MessageIcon from '@mui/icons-material/Message';
// import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import {
//   Box,
//   Grid,
//   InputAdornment,
//   Stack,
//   Typography,
//   useTheme,
// } from '@mui/material';
// import { Form, Formik } from 'formik';
// import Image from 'next/image';
// import { useContext } from 'react';
// import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';

// const INITIAL_FORM_STATE = {
//   name: '',
//   contactNo: '',
//   email: '',
//   remarks: '',
//   instagramLink: '',
//   youtubeLink: '',
//   facebookLink: '',
// };

// const FORM_VALIDATION = Yup.object().shape({
//   name: nameValidation('Name', false),
//   contactNo: validateContactNumber('Contact Number is required', false),
//   email: emailValidation('Email Id', false),
//   remarks: nameOtherValidationNoRegex('Remarks', false),
//   instagramLink: Yup.string().url('Invalid Instagram URL').nullable(),
//   youtubeLink: Yup.string().url('Invalid YouTube URL').nullable(),
//   facebookLink: Yup.string().url('Invalid Facebook URL').nullable(),
// });

// const ContactUs = () => {
//   const theme = useTheme();
//   const { setLoading } = useContext(LoadingContext);
//   const dispatch = useDispatch();
//   const submitDetails = async (values, { resetForm }) => {
//     try {
//       const data = {
//         ...values,
//       };

//       const response = await callApi('createFeedBack', data);

//       if (response.data.result) {
//         dispatch(
//           setAlertPopup({
//             message: response.data.message,
//             type: 'success',
//             duration: 3000,
//           })
//         );
//         resetForm();
//       } else {
//         console.error('Failed to fetch:', response.data.message);
//         dispatch(
//           setAlertPopup({
//             message: response.data.message,
//             type: 'error',
//             duration: 3000,
//           })
//         );
//         resetForm();
//       }
//     } catch (err) {
//       console.log('err', err);
//       dispatch(
//         setAlertPopup({
//           message: 'Something went wrong Please try again!',
//           type: 'error',
//           duration: 3000,
//         })
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Grid container spacing={2} >
//       <Grid
//         item
//         xs={12}
//         md={6}
//         sm={6}
//         alignItems={'center'}
//         justifyContent={'center'}
//       >
//         <Stack
//           direction="column"
//           justifyContent="center"
//           alignItems="center"
//           spacing={1}
//         >
//           <Typography variant="h2">Feedback</Typography>

//           <Formik
//             enableReinitialize
//             initialValues={{
//               ...INITIAL_FORM_STATE,
//             }}
//             validationSchema={FORM_VALIDATION}
//             onSubmit={async (values, { resetForm }) => {
//               console.log(values);
//               // alert('Form submitted');
//               submitDetails(values, { resetForm });
//               // await new Promise(resolve => setTimeout(resolve, 1000));
//               // resetForm();
//             }}
//           >
//             {({
//               errors,
//               handleBlur,
//               handleChange,
//               handleSubmit,
//               setFieldValue,
//               isSubmitting,
//               touched,
//               values,
//             }) => (
//               <Form noValidate onSubmit={handleSubmit}>
//                 <Box sx={{ p: 4 }}>
//                   <Grid
//                     container
//                     spacing={2}
//                     alignItems={'center'}
//                     justifyContent={'center'}
//                   >
//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="name"
//                         label="Name"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <AccountBoxRoundedIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="contactNo"
//                         label="Contact Number"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <SmartphoneRoundedIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="email"
//                         label="Email Id"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <EmailRoundedIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="remarks"
//                         label="Remarks"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <MessageIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <Typography variant="h5" text>
//                         Note: Please copy the URL and paste the social media links in the input area below.
//                       </Typography>
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="instagramLink"
//                         label="Instagram"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <InstagramIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="facebookLink"
//                         label="Facebook"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <FacebookIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12} l>
//                       <TextfieldWrapper
//                         name="youtubeLink"
//                         label="YouTube"
//                         otherProps={otherPropsNotRequired}
//                         InputProps={{
//                           startAdornment: (
//                             <InputAdornment position="start">
//                               <YouTubeIcon />
//                             </InputAdornment>
//                           ),
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={12} container justifyContent="center">
//                       <ShadowButtonSubmit
//                         height="45px"
//                         width="50%"
//                         minwidth="100px"
//                         maxwidth="500px"
//                         backgroundcolor={theme.palette.primary.main}
//                         type="submit"
//                         onClick={handleSubmit}
//                       >
//                         <SubmitButtonText color="#fff">Submit</SubmitButtonText>
//                       </ShadowButtonSubmit>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               </Form>
//             )}
//           </Formik>
//         </Stack>
//       </Grid>

//       <Grid
//         item
//         xs={12}
//         md={6}
//         sm={6}
//         alignItems={'center'}
//         justifyContent={'center'}
//       >
//         <Image
//           src={IMAGES?.CONTACT_US}
//           alt="Contact Us"
//           width={450}
//           height={350}
//         />
//       </Grid>
//     </Grid>
//   );
// };

// export default ContactUs;

//RECENT - 1
// 'use client';

// import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
// import TextfieldWrapper from '@/components/formComponents/Textfield';
// import { otherPropsNotRequired } from '@/components/formComponents/requiredStyleJson/otherProps';
// import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
// import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
// import { setAlertPopup } from '@/store/alertSlice';
// import { callApi } from '@/utils/apirequest';
// import {
//   emailValidation,
//   nameOtherValidationNoRegex,
//   nameValidation,
//   validateContactNumber,
// } from '@/utils/validations/CommonValidations';
// import IMAGES from '@/utils/validations/ImagePaths';
// import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
// import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import MessageIcon from '@mui/icons-material/Message';
// import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import {
//   Box,
//   Container,
//   Grid,
//   InputAdornment,
//   Stack,
//   Typography,
//   useTheme
// } from '@mui/material';
// import { Form, Formik } from 'formik';
// import Image from 'next/image';
// import { useContext } from 'react';
// import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';

// const INITIAL_FORM_STATE = {
//   name: '',
//   contactNo: '',
//   email: '',
//   remarks: '',
//   instagramLink: '',
//   youtubeLink: '',
//   facebookLink: '',
// };

// const FORM_VALIDATION = Yup.object().shape({
//   name: nameValidation('Name', false),
//   contactNo: validateContactNumber('Contact Number is required', false),
//   email: emailValidation('Email Id', false),
//   remarks: nameOtherValidationNoRegex('Remarks', false),
//   instagramLink: Yup.string().url('Invalid Instagram URL').nullable(),
//   youtubeLink: Yup.string().url('Invalid YouTube URL').nullable(),
//   facebookLink: Yup.string().url('Invalid Facebook URL').nullable(),
// });

// const ContactUs = () => {
//   const theme = useTheme();
//   const { setLoading } = useContext(LoadingContext);
//   const dispatch = useDispatch();
//   const submitDetails = async (values, { resetForm }) => {
//     try {
//       const data = {
//         ...values,
//       };

//       const response = await callApi('createFeedBack', data);

//       if (response.data.result) {
//         dispatch(
//           setAlertPopup({
//             message: response.data.message,
//             type: 'success',
//             duration: 3000,
//           })
//         );
//         resetForm();
//       } else {
//         console.error('Failed to fetch:', response.data.message);
//         dispatch(
//           setAlertPopup({
//             message: response.data.message,
//             type: 'error',
//             duration: 3000,
//           })
//         );
//         resetForm();
//       }
//     } catch (err) {
//       console.log('err', err);
//       dispatch(
//         setAlertPopup({
//           message: 'Something went wrong Please try again!',
//           type: 'error',
//           duration: 3000,
//         })
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Stack>
//       <Container style={{ position: 'relative', minHeight: '100vh' }}>

//         <Image
//           src={IMAGES?.CONTACT_US}
//           alt="Background Image"
//           layout="fill"
//           objectFit="cover"
//           objectPosition="center"

//         />
//         <Grid container spacing={2} >
//           <Grid
//             item
//             xs={12}
//             md={6}
//             sm={6}
//             alignItems={'center'}
//             justifyContent={'center'}
//           >
//             <Stack
//               direction="column"
//               justifyContent="center"
//               alignItems="center"
//               spacing={1}
//             >
//               <Typography variant="h2">Feedback</Typography>
//               <card variant="outlined">
//                 <Formik
//                   enableReinitialize
//                   initialValues={{
//                     ...INITIAL_FORM_STATE,
//                   }}
//                   validationSchema={FORM_VALIDATION}
//                   onSubmit={async (values, { resetForm }) => {
//                     console.log(values);
//                     // alert('Form submitted');
//                     submitDetails(values, { resetForm });
//                     // await new Promise(resolve => setTimeout(resolve, 1000));
//                     // resetForm();
//                   }}
//                 >
//                   {({
//                     errors,
//                     handleBlur,
//                     handleChange,
//                     handleSubmit,
//                     setFieldValue,
//                     isSubmitting,
//                     touched,
//                     values,
//                   }) => (
//                     <Form noValidate onSubmit={handleSubmit}>

//                       <Box sx={{ p: 4 }}>
//                         <Grid
//                           container
//                           spacing={2}
//                           alignItems={'center'}
//                           justifyContent={'center'}
//                         >
//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="name"
//                               label="Name"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <AccountBoxRoundedIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="contactNo"
//                               label="Contact Number"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <SmartphoneRoundedIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="email"
//                               label="Email Id"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <EmailRoundedIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="remarks"
//                               label="Remarks"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <MessageIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <Typography variant="h5" text>
//                               Note: Please copy the URL and paste the social media links in the input area below.
//                             </Typography>
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="instagramLink"
//                               label="Instagram"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <InstagramIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="facebookLink"
//                               label="Facebook"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <FacebookIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12} l>
//                             <TextfieldWrapper
//                               name="youtubeLink"
//                               label="YouTube"
//                               otherProps={otherPropsNotRequired}
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <YouTubeIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </Grid>

//                           <Grid item xs={12} container justifyContent="center">
//                             <ShadowButtonSubmit
//                               height="45px"
//                               width="50%"
//                               minwidth="100px"
//                               maxwidth="500px"
//                               backgroundcolor={theme.palette.primary.main}
//                               type="submit"
//                               onClick={handleSubmit}
//                             >
//                               <SubmitButtonText color="#fff">Submit</SubmitButtonText>
//                             </ShadowButtonSubmit>
//                           </Grid>
//                         </Grid>
//                       </Box>
//                     </Form>
//                   )}
//                 </Formik>
//               </card>

//             </Stack>
//           </Grid>

//         </Grid>
//       </Container>
//     </Stack>
//   );
// };

// export default ContactUs;
