'use client';
import IMAGES from '@/utils/validations/ImagePaths';
import { Box, Grid, Stack, Typography } from '@mui/material';

const OurPartners = () => {
  return (
    // <Box sx={{ p: 2 }}>
    //   <Stack direction="column"
    //     justifyContent="center"
    //     alignItems="center"
    //     spacing={2}>
    //     <Typography variant="h2">
    //       Our Collaborations and Partners
    //     </Typography>
    //     <Grid container
    //       alignItems={'center'}
    //       justifyContent={'center'}
    //     >
    //       {partnerList.map((element, index) => (
    //         <Grid item xs={6} sm={4} md={4} lg={3} key={element.partnerName}>
    //           <Stack direction="column" alignItems="center" spacing={1}>
    //             <img
    //               src={element.imageDetails.imagePath}
    //               alt={element.id}
    //               width={50}
    //               height={50}
    //             />
    //             <Typography variant="body2" align="center">
    //               {element.partnerName}
    //             </Typography>
    //           </Stack>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Stack>
    // </Box>
    <Box sx={{ p: 2, width: '100%', margin: 'auto' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        align="center"
        spacing={2}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Our Collaborations and Partners
        </Typography>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          {partnerList.map(element => (
            <Grid item xs={6} sm={6} md={4} lg={2} key={element.partnerName}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box
                  component="img"
                  sx={{
                    height: 50,
                    width: 50,
                    borderRadius: '50%',
                    objectFit: 'contain',
                  }}
                  src={element.imageDetails.imagePath?.default?.src}
                  alt={element.partnerName}
                />
                <Typography variant="body1" align="center">
                  {element.partnerName}
                  {JSON.stringify(element.imageDetails.imagePath.src)}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default OurPartners;

const partnerList = [
  {
    id: 1,
    partnerName: 'VCNR Groups',
    imageDetails: {
      imagePath: IMAGES.VCNR,
      imageType: '.webp',
    },
  },
  {
    id: 2,
    partnerName: 'Harsha Institution',
    imageDetails: {
      imagePath: IMAGES.HARSHA,
      imageType: '.png',
    },
  },
  {
    id: 3,
    partnerName: 'De Manderian',
    imageDetails: {
      imagePath: IMAGES.MANDERIAN,
      imageType: '.png',
    },
  },
  {
    id: 4,
    partnerName: 'Paragon',
    imageDetails: {
      imagePath: IMAGES.PARAGON,
      imageType: '.png',
    },
  },
  {
    id: 5,
    partnerName: 'Robots Tatoo',
    imageDetails: {
      imagePath: IMAGES.ROBOT,
      imageType: '.png',
    },
  },
];
