'use client';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import { SubTitle } from '@/components/textComponents';
import { callApi } from '@/utils/apirequest';
import { Box, Button, Stack } from '@mui/material';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import config from 'config';
import { useRouter } from 'next/navigation';
import React, { useContext, useEffect, useState } from 'react';

// function getIconByCategory(categoryName) {
//   switch (categoryName) {
//     case "Hotels":
//       return "https://cdn.pixabay.com/photo/2019/04/04/15/17/smartphone-4103051_1280.jpg";
//     case "Cloths":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "Resort":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1281.jpg";
//     case "park":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "cafe":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "museum":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "grocery store":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "gym":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "movie theater":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     case "bookstore":
//       return "https://cdn.pixabay.com/photo/2024/01/05/16/04/rocks-8489732_1280.jpg";
//     default:
//       return null;
//   }
// }
const containerStyle = {
  width: '100%',
  height: '100vh',
};
const center = { lat: 13.1012, lng: 77.3959 };

const Map = ({ mapRef }) => {
  // const Map = ({mapRef}) => {
  const router = useRouter();

  const { setLoading } = useContext(LoadingContext);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.mapKey,
    libraries: config.library,
  });

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [map, setMap] = useState([]);

  // const handleMarkerClick = (location) => {
  //   setSelectedLocation(location);
  // };

  const handleMarkerClick = (place, address) => {
    setSelectedLocation({ ...place, address: address });
  };
  const handleLogin = placeId => {
    const queryParams = new URLSearchParams({
      // user: "exampleUser",
      // role: "admin",
      placeId: placeId,
    }).toString();

    router.push(`/categories?${queryParams}`);
  };

  const fetchBusinessUnitList = async () => {
    setLoading(true);

    try {
      const data = {
        activeStatus: true,
      };

      const response = await callApi('getBusinessUnit', data);
      setLoading(false);
      console.log(response);
      if (response && response.data && response.data.result) {
        setMap(response.data.result);
      } else {
        setMap([]);
      }
    } catch (err) {
      console.error('Failed to fetch login:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBusinessUnitList();
  }, []);

  return (
    // <Box sx={{ p: 2 }} ref={mapRef}>
    <Box sx={{ p: 2 }} ref={mapRef}>
      {isLoaded && (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {map &&
            map.length > 0 &&
            map.map((address, index) => (
              <React.Fragment key={index}>
                {console.log(address?.businessImage?.imageURL)}
                {address.businessAddress.map((place, addressIndex) => (
                  <Marker
                    key={addressIndex}
                    position={{ lat: place.lat, lng: place.lng }}
                    icon={{
                      // url: getIconByCategory(address.categoryName),
                      url: `${config.host}${address?.businessImage?.imageURL}`,
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                    title={place.city}
                    onClick={() => handleMarkerClick(place, address)}
                  />
                ))}
              </React.Fragment>
            ))}

          {selectedLocation && (
            <InfoWindow
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <>
                <Stack spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <SubTitle color="#D32F2F">
                      {selectedLocation?.address?.businessName}
                    </SubTitle>
                    {/* {<SubTitle>Category: {selectedLocation?.address?.categoryName}</SubTitle> }  */}
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      onClick={() =>
                        handleLogin(selectedLocation?.address?.businessUuid)
                      }
                    >
                      More
                    </Button>
                  </Stack>
                </Stack>
              </>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </Box>
  );
};
export default Map;
